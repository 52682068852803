import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Spinner } from '@harmoney/ui-design-system';

const PAGE_NOT_FOUND = 'Page Not Found';

const Custom404 = () => {
  const router = useRouter();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!window.location.href.includes('/app/')) {
      setShowLoading(false);
      return;
    }
    const taskURL = '/task' + window.location.search;
    if (taskURL) {
      router.replace(taskURL);
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>{PAGE_NOT_FOUND}</title>
      </Head>
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        {!showLoading && (
          <div className="flex items-center rounded-lg p-8 shadow-md">
            <h1 className="mr-4 text-2xl font-bold">404</h1>
            <p className="text-gray-700">{PAGE_NOT_FOUND}</p>
          </div>
        )}
        {showLoading && <Spinner />}
      </div>
    </>
  );
};

export default Custom404;
